<!--验货管理-->
<template>
  <div class="container">
    <!-- 按钮列表 -->
    <div
      class="btnList1"
      ref="btnList"
    >
      <el-button
        v-if="hasPermiss('bgrk_yanHuoGuanLi:add')"
        class="itemBtn btnColor"
        @click="NewReject"
      >新建验货</el-button>
      <el-button
        v-if="hasPermiss('bgrk_yanHuoGuanLi:update')"
        class="itemBtn btnColor"
        @click="returnGoods"
      >修改</el-button>
      <el-button
        v-if="hasPermiss('bgrk_yanHuoGuanLi:del')"
        class="itemBtn btnColor"
        @click="rejection"
      >删除申请</el-button>
      <el-button
        v-if="hasPermiss('bgrk_yanHuoGuanLi:handel')"
        class="itemBtn btnColor"
        @click="RejectionTreatment"
      >验货处理</el-button>
      <el-button
        class="itemBtn btnColor"
        @click="lookDetails"
      >查看详情</el-button>
      <el-button
        class="itemBtn btnColor"
        @click="myModel"
      >表格设置</el-button>
      <el-dropdown @command="handleCommand_dc">
        <el-button class="dropdownBtn">
          导出<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="dc">导出</el-dropdown-item>
          <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
          <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- 查询条件框 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <div class="singleinp1">
            <div class="singleinpleft">验货申请日期:</div>
            <div class="singleinpright">
              <el-date-picker
                style="width: 100%"
                value-format="yyyy-MM-dd"
                @change="paymentTime"
                v-model="time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">快递单号:</div>
            <div class="singleinpright">
              <el-input
                class="queryInput"
                clearable
                v-model="number1"
                placeholder="请输入内容"
              >
              </el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">验货状态:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.checkStatus"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in inspectionArr"
                  :key="index"
                  :label="item.showName"
                  :value="item.statusVal"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员号:</div>
            <div class="singleinpright">
              <el-input
                class="queryInput"
                v-model="queryData.memberId"
                placeholder="请输入内容"
              >
              </el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员名称:</div>
            <div class="singleinpright">
              <el-input
                class="queryInput"
                v-model="queryData.memberName"
                placeholder="请输入内容"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div
          class="item_left"
          v-if="hasPermiss('bgrk_yanHuoGuanLi:query')"
        >
          <div class="singleinpbtn">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
            >查询</el-button>
            <el-tooltip
              content="查询项恢复初始状态"
              placement="top"
            >
              <div
                class="condition"
                @click="resetFormQ"
              >重 置</div>
            </el-tooltip>
            <el-tooltip
              content="查询更多条件"
              placement="top"
            >
              <div
                class="condition"
                @click="more"
              >
                {{ MoreConditions }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-table
      :height="tableHeigth"
      ref="mytable"
      stripe
      :data="tableData"
      border
      show-summary
      :summary-method="getSummaries"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        fixed="left"
        align="center"
        label="序号"
        type="index"
        width="55"
      ></el-table-column>
      <el-table-column
        fixed="left"
        align="center"
        type="selection"
        width="55"
      ></el-table-column>
      <template v-for="(item, index) in myTableHeard">
        <template v-if="item.field === 'lengthWidthHeight'">
          <el-table-column
            :key="index"
            :label="item.name"
            sortable
            :min-width="item.width"
            :show-overflow-tooltip="true"
            align="center"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.length +
                " * " +
                scope.row.width +
                " * " +
                scope.row.height
              }}</span>
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column
            :key="index"
            :label="item.name"
            :prop="item.field"
            :min-width="item.width"
            :show-overflow-tooltip="true"
            align="center"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template>
      </template>
    </el-table>
    <!-- //排序 -->
    <sorttable
      :show_sortTableHeard="show_sortTableHeard"
      :allCheck="allCheck"
      :sortName="sortName"
      :tabelHeadeTitle="tabelHeadeTitle"
      :originalTabelHeadeTitle="originalTabelHeadeTitle"
      :selectLength="selectLength"
      @cancelSortHeard="cancelSortHeard"
      @confirmOk="confirmOk"
      @checktab="checktab"
      @checkAll="checkAll"
      @closeDias="closeDias"
    ></sorttable>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
    </paging>
    <el-dialog
      title="上传编辑好的导出模板"
      :visible.sync="show_upload"
      append-to-body
      width="40%"
      center
    >
      <el-upload
        :action="uploadUrl"
        :show-file-list="true"
        :file-list="fileList"
        :limit="1"
        :http-request="uploadSectionFile"
      >
        <el-button
          size="small"
          type="primary"
        >点击上传</el-button>
        <div
          slot="tip"
          class="el-upload__tip"
        >上传模板</div>
      </el-upload>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="show_upload = false">关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 详情弹窗 -->
    <el-dialog
      title="验货详情"
      :visible.sync="show_details"
      append-to-body
      width="80%"
      center
    >
      <div>
        <div class="newForm">
          <div class="M">
            <el-form
              :model="inspectionDetail"
              label-width="100px"
            >
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item label="快递单号:">
                    <el-input
                      v-model="inspectionDetail.courierNumber"
                      disabled
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="验货状态:">
                    <el-select
                      v-model="inspectionDetail.checkStatus"
                      clearable
                      placeholder="请选择"
                      disabled
                    >
                      <el-option
                        v-for="item in inspectionArr"
                        :key="item.value"
                        :label="item.showName"
                        :value="item.statusVal"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="仓库:">
                    <el-input
                      v-model="inspectionDetail.storageName"
                      disabled
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="inlineDiv">
                <div
                  class="queryItem1"
                  style="height: 30px"
                >
                  <el-form-item label="处理结果:">
                    <el-input
                      v-model="inspectionDetail.inspectionResult"
                      disabled
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="备注:">
                    <el-input
                      type="textarea"
                      v-model="inspectionDetail.comment"
                      disabled
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="包裹备注:">
                    <el-input
                      type="textarea"
                      v-model="inspectionDetail.packageComment"
                      disabled
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
          <!-- 表格 -->
          <div class="M">
            <el-table
              border
              :data="inspectionDetail.itemServiceList"
              style="width: 80%"
              size="small"
              :header-cell-style="{ background: '#f0f0f0' }"
            >
              <el-table-column
                label="序号"
                type="index"
                width="50"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="itemServiceTitle"
                align="center"
                label="验货要求"
              ></el-table-column>
              <el-table-column
                align="center"
                label="验货次数"
              >
                <template slot-scope="scope">
                  <el-input
                    readonly
                    v-model="scope.row.serviceCount"
                    size="mini"
                    style="width: 90%"
                  ></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="M">
            <div class="detailPics">
              <!-- <img
                class="detailImg"
                v-for="(itemp, indexp) in inspectionDetail.pics"
                :src="itemp.url"
                alt=""
              /> -->
              <el-image
                class="detailImg"
                fit="contain"
                v-for="(itemp, indexp) in inspectionDetail.pics"
                :key="indexp"
                :src="itemp.url"
                :preview-src-list="srcList"
              >
              </el-image>
            </div>
            <!-- <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog> -->
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="show_details = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiList, Api } from '@/assets/js/api'
import tools from '@/assets/js/tool'
import sorttable from '@/components/sortTemplate.vue'
import draggable from 'vuedraggable' //拖拽组件
import paging from '@/components/pagings.vue'

export default {
  inject: ['reload'],
  components: { draggable, paging, sorttable },
  data() {
    return {
      queryData: {}, //  搜索表单
      number1: '',
      inspectionArr: [], //  验货状态下拉
      isActive: true, //  输入框绑定动态class
      judge: false, //  输入框绑定动态class
      MoreConditions: '更多条件', //  输入框绑定动态class
      editData: [], //  点击复选框获取的数据
      tableHeigth: '50vh',
      tabelHeadeTitle: [
        {
          name: '快递单号',
          field: 'courierNumber',
          width: '140',
          sort: 5,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: '会员名称',
          field: 'memberName',
          width: '120',
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '会员号',
          field: 'memberId',
          width: '80',
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '包裹状态',
          field: 'packageStatusShow',
          width: '140',
          sort: 7,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '验货要求',
          field: 'itemServiceName',
          width: '140',
          sort: 5,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '验货单号',
          field: 'number',
          width: '180',
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '处理状态',
          field: 'checkStatusShow',
          width: '80',
          sort: 5,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '仓库',
          field: 'storageName',
          width: '80',
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '货架号',
          field: 'frameNumber',
          width: '80',
          sort: 6,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: '快递公司',
          field: 'expressName',
          width: '120',
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: '货物名称',
          field: 'itemName',
          width: '80',
          sort: 11,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '包裹件数',
          field: 'boxCount',
          width: '80',
          sort: 12,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: '包裹重量(KG)',
          field: 'packageWeight',
          width: '140',
          sort: 13,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: '包裹体积(m³)',
          field: 'volume',
          width: '140',
          sort: 14,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '长宽高(cm)',
          field: 'lengthWidthHeight',
          width: '120',
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: '包裹备注',
          field: 'comment',
          width: '140',
          sort: 15,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '申请时间',
          field: 'createTime',
          width: '140',
          sort: 15,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '申请人',
          field: 'applyUserName',
          width: '80',
          sort: 15,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ],
      originalTabelHeadeTitle: [],
      myTableHeard: [], //  我的表头
      tableData: [],
      totalArr: [], //  需要合计的字段
      className: '', //  导出需要

      form: {
        name: '',
        region: '',
      },
      // ======公用组件==========
      pageNum: 1, //页码
      time: [],
      total: 0,
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      show_details: false, //显示详情弹窗
      dialogVisible: false, //
      dialogImageUrl: '',
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: '', //上传路径
      sortName: '模板名', //排序名
      pageGroupName: 'InspectionManagement', //页面标识
      inspectionDetail: {},
      srcList: [],
    }
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1
      }
      this.tabelHeadeTitle = arr
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      )
    }
    this.getGroupFindFieldSort() //  获取用户自定义表头
    this.myGetStatusValList('bill_inspection_v1.check_status', 2) //  获取验货状态
    let endtime = tools.getCurrentDate() //今天的时间;
    let sarttime = tools.getFlexDate(-30) //30天前
    this.time = [sarttime, endtime]
    this.queryData.applyStartTime = sarttime + ' 00:00:00'
    this.queryData.applyEndTime = endtime + ' 23:59:59'
  },
  mounted() {
    this.getData()
    // window.onresize = () => {
    //   return (() => {
    //     this.fetTableHeight();
    //   })();
    // };
  },
  watch: {},
  methods: {
    //时间
    paymentTime(e) {
      if (e && e.length > 0) {
        this.queryData.applyStartTime = e[0] + ' ' + '00:00:00'
        this.queryData.applyEndTime = e[1] + ' ' + '23:59:59'
      } else {
        this.queryData.applyStartTime = ''
        this.queryData.applyEndTime = ''
      }
      this.queryBtn_ok()
    },
    more() {
      //  输入框绑定动态class
      this.isActive = !this.isActive
      this.judge = !this.judge
      this.fetTableHeight()
      if (this.judge) {
        this.MoreConditions = '收起条件'
      } else {
        this.MoreConditions = '更多条件'
      }
    },

    getData() {
      // 获取数据
      this.queryData.pageStart = this.pageNum
      this.queryData.pageTotal = this.size
      Api.inspectionPackageList(this.queryData).then((res) => {
        if (res.data.status === 'success') {
          this.className = res.data.result.className || ''
          this.tableData = res.data.result.data || []
          this.total = res.data.result.pageCount || 0
          this.fetTableHeight()
        }
      })
    },

    queryBtn_ok() {
      //  查询按钮
      let nidlist = new Array()
      nidlist = tools.getOrderNum(this.number1)
      this.queryData.courierNumbers = nidlist
      this.pageNum = 1
      this.getData()
    },

    resetFormQ() {
      //   重置按钮
      this.queryData = {}
      this.number1 = ''
      this.time = []
    },
    myGetStatusValList(s, t) {
      Api.getStatusValList({
        tableAndFieldName: s,
      }).then((res) => {
        if (res.data.status === 'success') {
          if (t === 2) {
            this.inspectionArr = res.data.result
          }
        } else {
          console.log(`${res.data.message}--获取状态`)
        }
      })
    },
    //删除
    delbut(nid) {
      ////console.log(nid)
      var sid = nid
      this.tableData1.splice(sid, 1)
    },
    //导出操作
    handleCommand_dc(command) {
      if (command == 'dc') {
        // 导出
        this.exportBtn()
      } else if (command == 'dcmb') {
        // 下载模板
        this.exportTemplateDownload()
      } else if (command == 'scmb') {
        // 上传模板
        this.upLoadBtn()
      }
    },
    //新建验货
    NewReject() {
      this.$router.push({
        path: '/BusinessManagement/Warehousing/InspectionHandling',
        query: { pageType: 1 },
      })
    },
    //修改
    returnGoods() {
      if (this.editData.length === 1) {
        if (this.editData[0].checkStatus !== 'dai_yan_huo:check_status') {
          this.$message.warning('只有待验货的单可以进行验货修改')
          return
        }
        this.$router.push({
          path: '/BusinessManagement/Warehousing/InspectionHandling',
          query: {
            pageType: 3,
            id: this.editData[0].inspectionId,
            packageComment: this.editData[0].comment,
            storageName: this.editData[0].storageName,
          },
        })
      } else {
        this.$message.warning('请选择一条数据')
      }
    },
    //删除申请
    rejection() {
      this.$confirm('是否删除拒收申请?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let ids = []
        this.editData.forEach((item) => {
          ids.push(item.inspectionId)
        })
        let param = {
          inspectionIds: ids,
        }
        param.sign = tools.getSign(param)
        Api.deleteInspection(param).then((res) => {
          if (res.data.status === 'success') {
            this.$message.success(res.data.message || '')
            this.getData()
          }
        })
      })
    },
    //验货处理
    RejectionTreatment() {
      if (this.editData.length === 0) {
        this.$message.warning('请选择一条数据')
      } else if (this.editData.length > 1) {
        this.$message.warning('一次只能处理一条数据哦')
      } else {
        if (this.editData[0].checkStatus !== 'dai_yan_huo:check_status') {
          this.$message.warning('只有待验货的单可以进行验货处理')
          return
        }
        this.$router.push({
          path: '/BusinessManagement/Warehousing/InspectionHandling',
          query: { pageType: 2, id: this.editData[0].inspectionId },
        })
      }
    },
    // 查看详情
    lookDetails() {
      if (this.editData.length === 0) {
        this.$message.warning('请选择一条数据')
      } else if (this.editData.length > 1) {
        this.$message.warning('一次只能查看一条数据哦')
      } else {
        Api.getInspectionDetail({
          inspectionId: this.editData[0].inspectionId,
        }).then((res) => {
          this.inspectionDetail = res.data.result
          let srcList = this.inspectionDetail.pics || []
          let newList = []
          srcList.forEach((item) => {
            newList.push(item.url)
          })
          this.srcList = newList
          this.show_details = true
        })
      }
    },

    // 导出部分====

    // 上传导出模板
    uploadSectionFile(param) {
      //console.log(param);
      const file = param.file,
        fileName = file.name

      // 根据后台需求数据格式
      const form = new FormData()
      // 文件对象
      form.append('file', file)
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append('fileName', fileName)
      let signArr = [
        {
          key: 'fileName',
          val: fileName,
        },
      ]
      let sign = tools.getMD5Sign(signArr)
      form.append('sign', sign)
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == 'success') {
            let fileName = res.data.result.fileName

            let signArr = [
              {
                key: 'groupName',
                val: this.pageGroupName,
              },
              {
                key: 'templateName',
                val: this.sortName,
              },
              {
                key: 'fileName',
                val: fileName,
              },
            ]
            let sign_ = tools.getMD5Sign(signArr)
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || '更新成功')
              this.show_upload = false
            })
          }
        })
        .catch((err) => {})
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName
      var head = this.head
      if (head.length == 0) {
        let fieldNames = []
        let heads = []
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field)
            heads.push(this.myTableHeard[i].name)
          }
        }
        fieldName = fieldNames
        head = heads
      }
      //加密数组格式
      let signArr = [
        {
          key: 'fieldName',
          val: fieldName,
        },
        {
          key: 'head',
          val: head,
        },
      ]
      let sign = tools.getMD5Sign(signArr)
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      }
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || ''
        //下载(这里其实是直接访问)
        var a = document.createElement('a')
        a.setAttribute('href', downloadurl)
        a.setAttribute('target', '_blank')
        a.click()
      })
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this
      let datas = that.tableData
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      }
      let signarr = tools.getSignArr(parm)
      let signs_e = tools.getMD5Sign(signarr)
      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl
        var a = document.createElement('a')
        a.setAttribute('href', downloadurl)
        a.setAttribute('target', '_blank')
        a.click()
      })
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true
    },
    // 点击导出按钮
    exportBtn() {
      let that = this
      let fieldName = []
      let head = []
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field)
          head.push(this.myTableHeard[i].name)
        }
      }
      this.fieldName = fieldName
      this.head = head

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId
          that.templateDataExport(customizedExportId)
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            '验货管理列表'
          )
        }
      })
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */

    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0
        resolve()
      })
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight()
        this.$nextTick(() => {
          this.$refs.mytable.doLayout()
        })
      })
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight
      let queryHeigth
      let btnListHeight = this.$refs.btnList.offsetHeight + 16 // 按钮列表  16==上下的margin
      if (this.judge) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight //查询框
      } else {
        queryHeigth = 50
      }
      let pagsHeigth = 60 //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 30
      return Math.floor(windonHeight - otherHeight) || 'auto' //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 提取快递单输入框的内容(无规则输入,定义了 换行,',' ,'空格三种')
    getStr(orderNos) {
      let arr = []
      let arr_1 = []
      if (orderNos.indexOf('\n') !== -1) {
        arr = orderNos.split('\n')
        //  ['123 344,dsg ewe','234 233','32,234']
        for (var i = 0; i < arr.length; i++) {
          let arr_a = []
          if (arr[i].indexOf(',') !== -1) {
            arr_a = arr[i].split(',')
            arr_a.map((item) => {
              arr_1.push(item)
            })
          } else {
            if (arr[i]) {
              arr_1.push(arr[i])
            }
          }
        }
        setTimeout(() => {
          for (var j = 0; j < arr_1.length; j++) {
            let arr_b = []
            if (arr_1[j].indexOf(' ') !== -1) {
              arr_b = arr_1[j].split(' ')
              arr_b.map((item) => {
                arr_b.push(item)
              })
            } else {
              if (arr_1[j]) {
                arr_b.push(arr_1[j])
              }
            }
          }
        }, 20)
      } else if (orderNos.indexOf(',') !== -1) {
        arr = orderNos.split(',')
        //  ['123 344','234','32 234']
        for (var b = 0; b < arr.length; b++) {
          let arr_a = []
          if (arr[b].indexOf(' ') !== -1) {
            arr_a = arr[b].split(' ')
            arr_a.map((item) => {
              arr_1.push(item)
            })
          } else {
            if (arr[b]) {
              arr_1.push(arr[b])
            }
          }
        }
      } else if (orderNos.indexOf(' ') !== -1) {
        arr = orderNos.split(' ')
        arr.map((item) => {
          if (item) {
            arr_1.push(item)
          }
        })
      } else {
        arr_1 = [orderNos]
      }
      let endArr = []
      for (var q = 0; q < arr_1.length; q++) {
        if (arr_1[q].indexOf(' ') != -1) {
          let arrs = arr_1[q].split(' ')
          for (var w = 0; w < arrs.length; w++) {
            if (arrs[w]) {
              endArr.push(arrs[w])
            }
          }
        } else {
          if (arr_1[q]) {
            endArr.push(arr_1[q])
          }
        }
      }
      return endArr
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || []
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject)
            this.sortName = arr[0].sortName
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle
        }
        let totalArr = []
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field)
          }
        }
        that.totalArr = totalArr
      })

      that.$nextTick(() => {
        that.$refs.mytable.doLayout()
      })
    },
    // 设置模板按钮
    myModel() {
      let that = this
      that.show_sortTableHeard = true
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard))
      let selectLength = 0
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++
        }
      }
      that.selectLength = selectLength
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true
      } else {
        that.allCheck = false
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      this.editData = e
    },
    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false
      this.$nextTick(() => {
        this.$refs.mytable.doLayout()
      })
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e
      this.myTableHeard = this.tabelHeadeTitle
      let sortObject = this.myTableHeard
      let signs = [
        {
          key: 'sortName',
          val: this.sortName, //模板名称
        },
        {
          key: 'groupName',
          val: this.pageGroupName, //页面标识
        },
        {
          key: 'sortObject',
          val: JSON.stringify(sortObject),
        },
      ]

      let sign = tools.getMD5Sign(signs)

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      }
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || '操作成功')
        this.getGroupFindFieldSort()
      })
      this.show_sortTableHeard = false //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout()
      })
      this.show_sortTableHeard = false
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this
      that.tabelHeadeTitle[idx].isShow = e
      let selectLength = 0
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++
        }
      }
      that.selectLength = selectLength
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true
      } else {
        that.allCheck = false
      }
    },
    //全选
    checkAll(e) {
      let that = this
      this.allCheck = e
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e
      }
      let selectLength = 0
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++
        }
      }
      that.selectLength = selectLength
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e
    },
    // 合计
    getSummaries(param) {
      let that = this
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2)
                } else {
                  return prev
                }
              }, 0)
              sums[index] += ''
            } else {
              sums[index] = '---'
            }
          }
        }
      })
      this.$nextTick(() => {
        this.$refs.mytable.doLayout()
      })
      return sums
    },

    //分页
    handleSizeChange(val) {
      this.size = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getData()
    },
    // 上一页
    handlePrevPage() {
      this.pageNum = this.pageNum - 1 || 1
      this.getData()
    },
    // 下一页
    handleNextPage() {
      this.pageNum = this.pageNum + 1 || 1
      this.getData()
    },
  },
}
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}

.inpustyle {
  width: 400px;
}

.addbut {
  margin-left: 20px;
}

.headebut {
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
}
.singleinp1 {
  min-width: 392px;
}
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input,
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input__inner {
  width: 100%;
}

.detailPics {
  display: flex;
  flex-wrap: wrap;

  .detailImg {
    width: 200px;
    margin: 20px;
  }
}
</style>
